import type { Index } from 'o365.pwa.declaration.sw.dexie.objectStores.Index.d.ts';

declare module "o365.pwa.declaration.sw.dexie.objectStores.ObjectStore.d.ts" {
    export interface IObjectStoreUpdate {
        schema: string;
        objectStoreName: string;
    }

    export interface IIndexes {
        getAll: () => Promise<Array<Index>>;
    }

    export type Indexes = IIndexes & Record<Exclude<string, keyof IIndexes>, Promise<Index>>;

    export class ObjectStore {
        static objectStoreDexieSchema: string;

        public id: string;
        public appId: string;
        public databaseId: string;
        public jsonDataVersion: number | null;

        constructor(id: string, databaseId: string, appId: string, jsonDataVersion: number | null);

        public readonly indexes: Indexes;
        public readonly schema: Promise<string>;

        public save(): Promise<void>;
        public delete(): Promise<void>;
        public forceReload(): Promise<ObjectStore | null>;
    }
}
